import React from 'react'
import { Container, Spinner } from 'react-bootstrap'

const Loader = () => {
    return (
        <>
            <Container style={{ textAlign: 'center', height: '200px' }}>
                <Spinner 
                animation="border" 
                style={{ position: 'relative', top: '50%', height: '100px', width: '100px',color:'#eebb6b' }} />
            </Container>

        </>
    );
};

export default Loader;