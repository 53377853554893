import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import BlogCard from '../homepage/BlogCard'
import nothingFound from '../../images/NoRecordFound.png'
const OurLatestBlogsComponent = ({ data, page, perPage }) => {
    const blogData = data && data.slice((page - 1) * perPage, page * perPage)
    return (
        <Container fluid>
            <Container className="pb-5">
                <Row className="pt-5">
                    <Col className="services-section-right">
                        <div className="d-flex justify-content-start align-items-center">
                            <div className="line"></div>
                            <div>
                                <h6 className="subheading">OUR RECENT NEWS</h6>
                            </div>
                        </div>
                        <h2 >LATEST BLOGS</h2>
                    </Col>
                </Row>
                <Row>
                    {
                        blogData && blogData.length > 0 ? blogData.map((blog, index) => (
                            <React.Fragment key={`${blog.contentful_id}_${index}`}>
                                <BlogCard
                                    heading={blog.title}
                                    imageSrc={blog.media.file.url}
                                    tag={blog.tag}
                                    url={blog.url}
                                    id={blog.contentful_id} />
                            </React.Fragment>

                        )) :
                        <Container fluid >
                        <Container>
                            <Row>
                                <Col className="text-center">
                                <img src={nothingFound} alt="Nothing to show!!!" width="250" />
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                    }
                </Row>

            </Container>
        </Container>
    )
}

export default OurLatestBlogsComponent