import React, { Component } from 'react'
import OurLatestBlogsComponent from '../components/blogs/OurLatestBlogsComponent'
import BannerImage from '../components/elements/BannerImage'
import banner from '../images/slicing/blogs/banner.png'
import { addSubscriberMail } from '../middleware/apis'
import Loader from '../components/Loader'
import Pagination from '../components/Pagination'
class KnowledgeShareContainer extends Component {
    state = {
        subscribeEmail: '',
        page: 1,
        perPage: 6,
        loading: true
    }
    componentDidMount = () => {
        setTimeout(() => {
            this.setState({ loading: false })
        }, 2000)
    }

    handleSubscribeEmail = (subscribeEmail) => {
        this.setState({
            subscribeEmail
        })
    }
    submitSubscriptionForm = async () => {
        const { subscribeEmail } = this.state
        await addSubscriberMail({ email: subscribeEmail })
            .then((res) => {
                console.log("hello")
            })
            .catch(error => {
                console.log(error)
            })
        this.setState({
            subscribeEmail: ''
        })
    }
    handlePaginate = (page) => {
        this.setState({ page })
    }
    render() {
        const { data } = this.props
        const { page, perPage, loading } = this.state
        return (
            <>
                <BannerImage
                    activeTab="Blog & Updates"
                    bannerImage={banner} />

                {loading ? <Loader /> :

                    <OurLatestBlogsComponent
                        data={data && data}
                        perPage={perPage}
                        page={page}
                    />
                }
                {data && data.length > 0 && <Pagination
                    handlePaginate={this.handlePaginate}
                    page={page}
                    perPage={perPage}
                    data={data && data}
                />}

                <hr />
            </>
        )
    }
}

export default KnowledgeShareContainer