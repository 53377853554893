import React from 'react';
import { Container, Row, Button, Col, Dropdown, DropdownButton } from 'react-bootstrap'

const findTotalPages = (data,perPage) => {
    return data && data.length % perPage === 0 ? data.length / perPage : Math.ceil(data.length / perPage)
}
const generatePages = (totalPages) => {
    var pagesArray = [];
    for (var i = 0; i <= totalPages; i++) {
        pagesArray.push(i);
    }
    return pagesArray;
}
const Pagination = ({data,perPage,page,handlePaginate}) => {
    const totalPages = data && findTotalPages(data,perPage)
    console.log(totalPages,page)
    const pageArray = generatePages(totalPages)
    return (
        <Container>
            <Row>
                <Col className="font-weight-bold">
                    <Button onClick={() => handlePaginate(page - 1)} disabled={page === 1}>
                        &lt; Prev
                        </Button>

                </Col>
                <Col className="font-weight-bold text-center">
                    <DropdownButton as="ButtonGroup" id="select-page-number" title={"Page " + page}>
                        {pageArray && pageArray.map((page, index) => (
                            index !== 0 && <Dropdown.Item onClick={() => handlePaginate(page)}>{page}</Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Col>

                <Col className="text-right font-weight-bold" carot>
                    <Button onClick={() => handlePaginate(page + 1)} disabled={page === totalPages}>
                        Next &gt;
                        </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default Pagination;