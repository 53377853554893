import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import KnowledgeShareContainer from '../containers/KnowledgeShareContainer'

const KnowledgeSharePage = (props) => {
  return (
    <Layout
      pageInfo={{ pageName: "Knowledge Share" }}
      styleClass="our-story-page"
      mainClass="main-about"
      location={props.location.pathname}>
      <SEO title="Knowledge Share" />
      <KnowledgeShareContainer data={props.data && props.data.allContentfulBlogs.nodes} />
    </Layout>
  )
}

export default KnowledgeSharePage
export const query = graphql`
  {
    allContentfulBlogs {
      nodes {
        contentful_id
        createdAt
        tag
        title
        url
        description {
          content {
            content {
              value
            }
          }
        }
        media {
          file {
            url
          }
        }
      }
      totalCount
    }
  }
`