import React from 'react'
import { Col, Card, Badge, Row } from 'react-bootstrap'
import { Link } from 'gatsby'
const BlogCard = ({ heading, imageSrc, tag, id, url }) => {
    return (
        <>
            <Col sm="12" md="4" className="mt-sm-2" key={id}>
                <Card>
                    <Card.Img style={{ height: '230px' }} variant="top" src={imageSrc} />
                    <Card.Body>
                        <h5>
                            <Badge className="text-capitalize blog-tag">{tag}</Badge>
                        </h5>
                        <Card.Title as="h6" className="font-weight-bold">{heading}</Card.Title>
                        <Row>
                            <Col>
                                <Link to={`/knowledgeshare/${url}`} className="link-no-style btn" style={{ color: '#eebb6b' }}>
                                    Read More <div className="line-standard"></div>
                                </Link>
                            </Col>
                        </Row>

                    </Card.Body>
                </Card>

            </Col>
        </>
    );
};


export default BlogCard;